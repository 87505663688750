'use strict'

angular.module('cb.import', [])
	.controller('ImportLeadsController', ["$scope", "ImportTemplateService", "NotificationService", function ($scope, ImportTemplateService, NotificationService) {
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			}
			$scope.openPopups[variable] = true;
		};

		$scope.init = function () {
			$scope.LeadsImport = {};
			$scope.inProgress = false;

			$scope.GetTemplatesByTypePromise = ImportTemplateService.getTemplatesByType('Enquiries');
			$scope.GetTemplatesByTypePromise.then(function (output) {
				$scope.LeadTemplates = output.data.TemplateList;
			});

			$scope.GetAllLeadProdiversPromise = ImportTemplateService.getAllLeadProviders();
			$scope.GetAllLeadProdiversPromise.then(function (output) {
				$scope.LeadProviders = output.data.LeadProviders;
			});
		};

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.inProgress == true) {
				var answer = confirm('Import currently in Progress. Are you sure you wish to navigate away from this page?');
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if ($scope.inProgress == true) {
				var message = 'Import currently in Progress. Are you sure you wish to navigate away from this page?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.submit = function () {
			$scope.inProgress = true;
			if (document.getElementById('fileUpload').files.length) {
				var file = document.getElementById('fileUpload');
				var mimetypes = ['text/csv', 'application/vnd.ms-excel'];
				if (!(mimetypes.indexOf(file.files[0].type) != -1)) {
					NotificationService.alert('Wrong file type', file.files[0].type + 'The file that was uploaded was of the wrong file type.', 'alert', {});
					$scope.inProgress = false;
					return false;
				}
			
				var fileInfo = [];
				if (typeof (FileReader) != "undefined") {
					var reader = new FileReader();
					reader.onload = function (e) {
						var rows = e.target.result.split("\n");
						for (var i = 0; i < rows.length; i++) {
							rows[i].replace(/[\n\r]+/g, '');
							var cells = rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
							fileInfo.push(cells);
						}

						if (fileInfo[fileInfo.length - 1].length == 1) { fileInfo.splice(fileInfo.length - 1, 1); }
						var data = {};
						data.TemplateId = $scope.LeadsImport.Template.Id;
						data.LeadImportData = fileInfo;
						data.FileName = file.files[0].name;
						data.LeadProvider = $scope.LeadsImport.LeadProvider.Id;
						data.EnquiryDate = $scope.LeadsImport.EnquiryDate;

						$scope.ImportLeadsPromise = ImportTemplateService.importLeads(data);
						$scope.ImportLeadsPromise.then(function (output) {
							NotificationService.alert('Leads Imported', 'All Lead data was successfully imported.', 'success', {});
							$scope.inProgress = false;
						}, function (error) {
							NotificationService.alert('Leads Import Error', 'There was an error Importing all the lead data, please check the data and try again.', 'error', {});
							$scope.inProgress = false;
						});
					}

					reader.readAsText($("#fileUpload")[0].files[0]);
				} else {
					alert("This browser does not support HTML5.");
					$scope.inProgress = false;
				}
			} else {
				NotificationService.alert('No File Uploaded', 'There was no file uploaded, please make sure you add a file.', 'alert', {});
				$scope.inProgress = false;
			}
		};

	}])
	.controller('ImportProspectsController', ["$scope", "ImportTemplateService", "NotificationService", "$modal", function ($scope, ImportTemplateService, NotificationService, $modal) {
		$scope.init = function () {
			$scope.ProspectsImport = {};
			$scope.inProgress = false;

			$scope.GetTemplatesByTypePromise = ImportTemplateService.getTemplatesByType('prospects');
			$scope.GetTemplatesByTypePromise.then(function (output) {
				$scope.ProspectTemplates = output.data.TemplateList;
			});
		};

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.inProgress == true) {
				var answer = confirm('Import currently in Progress. Are you sure you wish to navigate away from this page?');
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if ($scope.inProgress == true) {
				var message = 'Import currently in Progress. Are you sure you wish to navigate away from this page?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.submit = function () {
			$scope.inProgress = true;
			if (document.getElementById('fileUpload').files.length) {
				var file = document.getElementById('fileUpload');
				var mimetypes = ['text/csv', 'application/vnd.ms-excel'];
				if (!(mimetypes.indexOf(file.files[0].type) != -1)) {
					NotificationService.alert('Wrong file type','The file that was uploaded was of the wrong file type.', 'alert', {});
					return false;
				}
				
				var fileInfo = [];
				if (typeof (FileReader) != "undefined") {
					var reader = new FileReader();
					reader.onload = function (e) {
						var rows = e.target.result.split("\n");
						for (var i = 0; i < rows.length; i++) {
							rows[i].replace(/[\n\r]+/g, '');
							var cells = rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
							fileInfo.push(cells);
						}

						if (fileInfo[fileInfo.length - 1].length == 1) { fileInfo.splice(fileInfo.length - 1, 1); }
						var data = {};
						data.TemplateId = $scope.ProspectsImport.Template.Id;
						data.ProspectImportData = fileInfo;
						data.FileName = file.files[0].name;

						$scope.ImportProspectsPromise = ImportTemplateService.importProspects(data);
						$scope.ImportProspectsPromise.then(function (output) {
							NotificationService.alert('Prospects Imported', 'All Prospect data was successfully imported.', 'success', {});
							$scope.inProgress = false;
							alert('Prospects Imported: ' + output.data.NoRecordsImported);
							if (output.data.PartCoverage.length)
								openPartCoverageModal({ BatchId: output.data.BatchId, Coverages: output.data.PartCoverage });
						}, function (error) {
							NotificationService.alert('Prospect Import Error', 'There was an error Importing all the prospect data, please check the data and try again.', 'error', {});
							$scope.inProgress = false;
						});
					}

					reader.readAsText($("#fileUpload")[0].files[0]);
				} else {
					alert("This browser does not support HTML5.");
					$scope.inProgress = false;
				}
			} else {
				NotificationService.alert('No File Uploaded', 'There was no file uploaded, please make sure you add a file.', 'alert', {});
				$scope.inProgress = false;
			}
		};

		function openPartCoverageModal(coverages) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				backdrop: 'static',
				templateUrl: 'views/modal-templates/part-coverages.html',
				controller: 'CoverageModalCtrl',
				resolve: {
					Data: () => {
						return coverages;
					}
				}
			});
			modalInstance.result.then(function () {
				NotificationService.alert('Coverages Sorted', 'Succesfully sorted part coverages.', 'success', {});
			});
		};

	}])

	.controller('CoverageModalCtrl', ["$scope", "ImportTemplateService", "NotificationService", "$modalInstance", "Data", function ($scope, ImportTemplateService,  NotificationService, $modalInstance, Data) {
		$scope.init = function () {
			$scope.BatchId = Data.BatchId;
			$scope.Data = Data.Coverages.map(function (item) {
				return { Name: item, Accept: true }
			});
		};

		$scope.confirmCoverages = function () {
			var postcodes = [];
			for (var i = 0; i < $scope.Data.length; i++) {
				if ($scope.Data[i].Accept == false) {
					postcodes.push($scope.Data[i].Name);
				}
			}
			var data = {
				BatchId: Data.BatchId,
				//PartCoverage: $scope.Data.filter(function (item) { !item.Accept }).map(function (item) { item.Name })
				PartCoverage: postcodes
			};
			$scope.UpdatePartCoveragesPromise = ImportTemplateService.flagPartCoverage(data);
			$scope.UpdatePartCoveragesPromise.then(function () {
				NotificationService.alert('Coverages Updated', 'Successfully updated the coverages accordingly.', 'success', {});
				$modalInstance.close();
			}, function () {
				NotificationService.alert('Coverage Update Error', 'Unable to update the coverages. Please try again.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('ImportTemplateController', ["$scope", "$modal", "ImportTemplateService", "NotificationService", "ParamService", "PickListService", function ($scope, $modal, ImportTemplateService, NotificationService, ParamService, PickListService) {
		$scope.init = function () {
			$scope.GetAllImportTemplatesPromise = ImportTemplateService.getAllImportTemplates();
			$scope.GetAllImportTemplatesPromise.then(function (output) {
				$scope.templates = output.data.ImportTemplatesList;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Import Template Error', 'There was an error retriving the import template data, please try again.', 'error', {});
			});

			var data = {};
			data = ['ImportTemplate'];
			$scope.getPickListByPickListNameImportTemplatePromise = PickListService.getPickListByPickListName(data);
			$scope.getPickListByPickListNameImportTemplatePromise.then(function (output) {
				$scope.ImportTemplatePickList = output.data.PickListEntries;
			});

			// get ColumnsPropertyName picklist

		};



		$scope.templatesPage = 1;
		$scope.templatesNextPage = true;

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

		$scope.openAddImportTemplateModal = function () {

			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'addImportTemplateModal.html',
				controller: 'AddImportTemplateModalCtrl',
				resolve: {
					ImportTemplatePickList: function () {
						return $scope.ImportTemplatePickList;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.init();
			})
		};

		$scope.openEditImportTemplateModal = function (col) {

			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editImportTemplateModal.html',
				controller: 'EditImportTemplateModalCtrl',
				resolve: {
					importTemplateId: function () {
						return col.Id;
					},
					ImportTemplatePickList: function () {
						return $scope.ImportTemplatePickList;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.init();
			})
		};
	}])

	.controller('AddImportTemplateModalCtrl', ["$scope", "$modalInstance", "ImportTemplateService", "NotificationService", "ImportTemplatePickList", function ($scope, $modalInstance, ImportTemplateService, NotificationService, ImportTemplatePickList) {
		$scope.NewImportTemplate = {};
		$scope.ImportTemplatePickList = ImportTemplatePickList;

		$scope.submit = function () {
			var data = {};
			data.Name = $scope.NewImportTemplate.Name;
			data.Description = $scope.NewImportTemplate.Description;
			data.FirstRowIsHeader = $scope.NewImportTemplate.FirstRowIsHeader || false;
			data.Type = $scope.NewImportTemplate.Type;

			$scope.CreateNewImportTemplatePromise = ImportTemplateService.createNewImportTemplate(data);
			$scope.CreateNewImportTemplatePromise.then(function (output) {
				NotificationService.alert('Import Template Created', 'Import Template was created successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Import Template Creation Failed', 'There was an error creating the Import Template, please try again.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])

	.controller('EditImportTemplateModalCtrl', ["$scope", "$modal", "$modalInstance", "ImportTemplateService", "NotificationService", "PickListService", "importTemplateId", "ImportTemplatePickList", "orderByFilter", function ($scope, $modal, $modalInstance, ImportTemplateService, NotificationService, PickListService, importTemplateId, ImportTemplatePickList, orderByFilter) {

		$scope.ImportTemplatePickList = ImportTemplatePickList;

		$scope.init = function () {
			$scope.GetImportTemplateByIdPromise = ImportTemplateService.getImportTemplateById(importTemplateId);
			$scope.GetImportTemplateByIdPromise.then(function (output) {
				$scope.EditImportTemplate = output.data;
				var type;
				ImportTemplatePickList.forEach(function (current) {
					if (current.Id == $scope.EditImportTemplate.Type) {
						//type = current.PickListEntry;
						$scope.TemplateType = current.Id;
						if ($scope.pl != undefined)
							$scope.ChangeType($scope.TemplateType);
					}
						
				});
				/* if (type) {
					getParamsForImportTemplateByType(type);
				} else {
					NotificationService.alert('Param Parse Error', 'Couldn\'t get the params for the specified type.', 'error', {});
				} */
			}, function (error) {
				NotificationService.alert('Import Template Error', 'There was an Error getting the Import Template information, please try again', 'error', {});
			});
			var data = {};
			data = ['ColumnsPropertyName', 'LeadColumnsPropertyName'];
			$scope.PickListColumnsPropertyNamePromise = PickListService.getPickListByPickListName(data);
			$scope.PickListColumnsPropertyNamePromise.then(function (output) {
				$scope.pl = output.data.PickListEntries;
				if ($scope.TemplateType != undefined)
					$scope.ChangeType($scope.TemplateType);
			}, function (error) {
				NotificationService.alert('Picklist Error', 'There was an error retriving picklist entries, please try reloading this page.', 'error', {});
			});
		};
		$scope.init();

		$scope.ChangeType = function (input) {
			var templateType = $scope.ImportTemplatePickList.find(p => p.Id == input).PickListEntry;
			if(templateType == 'Enquiries')
				$scope.displayPL = $scope.pl.filter(p => p.PickListName == 'LeadColumnsPropertyName');
			else if (templateType == 'Prospects')
				$scope.displayPL = $scope.pl.filter(p => p.PickListName == 'ColumnsPropertyName');
		}

		function getParamsForImportTemplateByType(type) {
			$scope.GetParamsByEntityAndFieldNamePromise = ParamService.getParamByEntityAndField(type, 'Import');
			$scope.GetParamsByEntityAndFieldNamePromise.then(function (output) {
				$scope.TemplateColumnNameParams = output.data.Params;
			}, function (error) {
				NotificationService.alert('Param Error', 'There was an error retriving param entries, please try reloading this page.', 'error', {});
			});

		};
		$scope.MoveColumnUp = function (col) {
			var oldindex = $scope.EditImportTemplate.TempalteColumns.indexOf(col)
			if (oldindex > 0) {
				ArrayMove($scope.EditImportTemplate.TempalteColumns, oldindex, oldindex - 1)
			}
		}
		$scope.MoveColumnDown = function (col) {
			var oldindex = $scope.EditImportTemplate.TempalteColumns.indexOf(col)
			if (!(oldindex >= $scope.EditImportTemplate.TempalteColumns.length)) {
				ArrayMove($scope.EditImportTemplate.TempalteColumns, oldindex, oldindex + 1)
			}
		}
		function ArrayMove(arr, old_index, new_index) {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		};
		$scope.AddColumn = function (NewCol) {
			var data = {
				ImportTemplateId: importTemplateId,
				FieldName: NewCol.FieldName,
				PropertyNameId: NewCol.PropertyNameId,
			}

			$scope.AddColumnPromice = ImportTemplateService.createTemplateColumnForImportTemplate(data);
			$scope.AddColumnPromice.then(function (output) {
				$scope.init();
			}, function (err) {
				NotificationService.alert('AddColumn error', 'There was an error on trying to add a new column', 'error', {});
			});

		}
		$scope.removeColumn = function (col) {
			$scope.deleteTemplateColumnForImportTemplatePromise = ImportTemplateService.deleteTemplateColumnForImportTemplate(col.Id);
			$scope.deleteTemplateColumnForImportTemplatePromise.then(function (output) {
				$scope.init();
			}, function (error) {
				NotificationService.alert('Removal Error', 'There was an error removing the Template Column, please try again.', 'error', {});
			});
		};

		$scope.openAddTemplateColummModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'addTemplateColumnModal.html',
				controller: 'AddTemplateColumnModalCtrl',
				resolve: {
					importTemplateId: function () {
						return importTemplateId;
					},
					TemplateColumnNameParams: function () {
						return $scope.TemplateColumnNameParams;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.init();
			})
		};

		$scope.save = function () {
			var data = {};
			data.Id = $scope.EditImportTemplate.Id
			data.Name = $scope.EditImportTemplate.Name
			data.Description = $scope.EditImportTemplate.Description
			data.FirstRowIsHeader = $scope.EditImportTemplate.FirstRowIsHeader
			data.Type = $scope.EditImportTemplate.Type
			$scope.EditImportTemplate.TempalteColumns.map(function (item, index) {
				item.SortOrder = index;
				return item;
			});
			data.TempalteColumns = $scope.EditImportTemplate.TempalteColumns
			$scope.EditImportTemplatePromise = ImportTemplateService.editImportTemplate(data);
			$scope.EditImportTemplatePromise.then(function (output) {
				NotificationService.alert('Import Template Edited', 'Import Template was edited successfully.', 'success', {})
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Import Template Edited Error', 'There was an error editing the Import Template, please try again.', 'error', {});
				$modalInstance.dismiss('cancel');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])

	.controller('AddTemplateColumnModalCtrl', ["$scope", "$modalInstance", "ImportTemplateService", "NotificationService", "importTemplateId", "TemplateColumnNameParams", function ($scope, $modalInstance, ImportTemplateService, NotificationService, importTemplateId, TemplateColumnNameParams) {
		$scope.NewTemplateColumn = {};
		$scope.TemplateColumnNameParams = TemplateColumnNameParams;

		$scope.submit = function () {
			var data = {};
			data.ImportTemplateId = importTemplateId;
			data.ColumnNameId = $scope.NewTemplateColumn.ColumnNameId;

			$scope.createTemplateColumnForImportTemplatePromise = ImportTemplateService.createTemplateColumnForImportTemplate(data);
			$scope.createTemplateColumnForImportTemplatePromise.then(function (output) {
				NotificationService.alert('Template Column Created', 'Template Column was created successfully.', 'success', {})
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Template Column Error', 'There was an error creating new Template Column, please try again.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
